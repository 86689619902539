import React from 'react';
import Navbar from '../components/navbar';

import CategoryList from '../components/categorylsit'
import Footer2 from '../components/footer2';


const Category = () => {
  return (

    <>
      <Navbar></Navbar>
      <CategoryList></CategoryList>
      <Footer2/>
    </>

  )
}

export default Category;