import React from 'react'
import Navbar from '../components/navbar'

import Create from '../components/create'
import Footer2 from '../components/footer2'

const Createpage = () => {
  return (
<>
<Navbar></Navbar>

<Create></Create>
<Footer2/>

</>
  )
}

export default Createpage