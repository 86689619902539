import React from 'react'
import Navbar from '../components/navbar';
import About from '../components/about';

import Footer2 from '../components/footer2';

const Aboutpage = () => {
  return (
    <>
    
    <Navbar></Navbar>
    <About></About>
    
    <Footer2/>

    </>
  )
}

export default Aboutpage;