import React from 'react'
import Navbar from '../components/navbar'

import Cardlu from '../components/Card'
import Footer2 from '../components/footer2'


const Home = () => {
  return (

    <>
  <Navbar></Navbar>

  <Cardlu></Cardlu>
<Footer2/>
    
    </>
    
  )
}

export default Home